import Vue from "vue";
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
    routes:[
        {
            name:'download',
            path:'/download',
            component:()=>import(/* webpackChunkName: "npc" */ '@/pages/download'),
            meta:{
                title:'下载'
            }
        },
        {
            name:'register',
            path:'/register',
            component:()=>import(/* webpackChunkName: "npc" */ '@/pages/register'),
            meta:{
                title:'注册'
            }
        },
        {
            name:'agreement',
            path:'/agreement',
            component:()=>import(/* webpackChunkName: "npc" */ '@/pages/agreement'),
            meta:{
                title:'注册协议'
            }
        },
    ]
});


export default router